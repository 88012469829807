.gdrive-ui-handler {
    display: contents;

    img.logo {
        padding: 20px;
        position: absolute;
        left: 0;
        top: 0;
        pointer-events: pointer;
        width: 200px;
    }
    
    div.layout {
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    div.dialog {
        background-color: #f7f8f9;
        min-width: 500px;
        width: 800px;
        max-width: 1800px;
        z-index: 10020;
        box-shadow: 0 0 20px rgba(50, 96, 148, .2);
    }
    
    div.dialog-header {
        color: #333;
        font-size: 15px;
        letter-spacing: .9px;
        text-transform: uppercase;
        margin: 10px 20px 10px 20px;
    }
    
    div.dialog-content {
        font-size: 13px;
        font-weight: 300;
        margin: 10px 20px 10px 20px;
    }
    
    div.dialog-footnote {
        font-size: 10px;
        font-weight: 300;
        color: #777;
        margin: 10px 20px 10px 20px;
    }
    
    div.dialog-button-container {
        text-align: center;
        margin-bottom: 20px;
    }
    
    button.dialog-accept-button {
        background: #fff;
        color: #333;
        font-size: 13px;
        font-weight: 600;
        padding: 7px 20px;
        vertical-align: middle;
        display: inline-block;
        min-width: 100px;
        height: 34px;
        text-align: center;
        outline: none;
        box-sizing: border-box;
        border: 1px solid #23aae0;
        text-transform: uppercase;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;
    }
    
    button.dialog-accept-button:hover {
        color: #333!important;
        background: #d6eff9!important;
    }
    
    .spinner {
        position: absolute !important;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    
    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }
    
    @keyframes dash {
        0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35px;
        }
        100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124px;
        }
    }
    
    .spinner .circular {
        animation: rotate 2s linear infinite;
        width: 40px;
        height: 40px;
        transform-origin: center center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
    
    .spinner .path {
        stroke: #23aae0;
        fill: none;
        stroke-width: 4px;
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        stroke-miterlimit: 10;
        animation: dash 1.5s ease-in-out infinite;
        stroke-linecap: round;
    }
}
